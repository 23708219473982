import { Box, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'

const Header = () => {
  const { serviceContent } = useEpiContent<Content>()
  return (
    <Box mb={5}>
      <Typography variant="h2" component={'h1'} gutterBottom>
        {serviceContent.heading}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {serviceContent.ingress}
      </Typography>
    </Box>
  )
}

export default Header
