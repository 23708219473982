import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { refreshUserProfile } from '@trr/app-shell-communication'
import { useAuthentication, useEpiContent, useUser } from '@trr/app-shell-data'
import { useLazyPutContactInfoQuery } from 'api'
import { Content, InferZodSafeReturnType } from 'types'
import { SyntheticEvent, useEffect, useState } from 'react'
import { z } from 'zod'

import { OutcomeDialog } from '../OutcomeDialog'

type DataToVerify = {
  [x: string]: string | null
}

const useShouldRefreshToken = (dataToVerify: DataToVerify) => {
  const user = useUser()
  const { userRoles } = useAuthentication()
  const mobilePhone = user?.mobilePhone
  const emailAdress = user?.email

  const shouldRefreshToken =
    (userRoles.includes('MissingMobile') &&
      (!mobilePhone || mobilePhone === '') &&
      Object.keys(dataToVerify)[0] === 'mobilePhone') ||
    (userRoles.includes('MissingEmail') &&
      (!emailAdress || emailAdress === '') &&
      Object.keys(dataToVerify)[0] === 'email')

  return shouldRefreshToken
}

const VerificationBox = ({
  endpoint,
  dataToVerify,
}: {
  endpoint: string
  dataToVerify: DataToVerify
}) => {
  const { serviceContent } = useEpiContent<Content>()
  const { idToken } = useAuthentication()
  const shouldRefreshToken = useShouldRefreshToken(dataToVerify)
  const [tokenComparison, setTokenComparison] = useState<string | null>(null)
  const [showRefreshedTokenDialog, setShowRefreshedTokenDialog] =
    useState<boolean>(false)
  const [showDialogNoTokenRefresh, setShowDialogNoTokenRefresh] =
    useState<boolean>(false)
  const [valueHad400Error, setValueHad400Error] = useState(false)
  const [trigger, result] = useLazyPutContactInfoQuery()
  const { isSuccess, originalArgs, error } = result
  const successVerify =
    isSuccess && !!originalArgs?.endpoint.includes('verification')

  const codeSchema = z
    .string()
    .min(1, serviceContent.setErrorNonEmpty)
    .and(
      z
        .string()
        .min(6, serviceContent.verifyErrorChars)
        .max(6, serviceContent.verifyErrorChars)
    )

  useEffect(() => {
    if (tokenComparison && tokenComparison !== idToken) {
      setShowRefreshedTokenDialog(true)
    }
  }, [tokenComparison, idToken])

  const [code, setCode] = useState<InferZodSafeReturnType<typeof codeSchema>>()

  const handleBlurInput = (event: SyntheticEvent) => {
    const eventTarget = event.target as HTMLInputElement
    setCode(codeSchema.safeParse(eventTarget.value))
  }
  const handleClickButton = () => {
    if (typeof code === 'undefined') {
      setCode(codeSchema.safeParse(''))
      return
    }
    if (code.success) {
      void trigger({ endpoint, body: code.data })
      if (shouldRefreshToken) {
        setTokenComparison(idToken)
        refreshUserProfile()
        return
      }
      setShowDialogNoTokenRefresh(true)
    }
  }

  const handleChangeInput = (event: SyntheticEvent) => {
    if (error && 'status' in error && 'originalPayload' in error) {
      const eventTarget = event.target as HTMLInputElement
      if (error.status === 400 && eventTarget.value === error.originalPayload) {
        setValueHad400Error(true)
        return
      }
      setValueHad400Error(false)
    }
  }
  useEffect(() => {
    if (error && 'status' in error && error.status === 400) {
      setValueHad400Error(true)
    } else {
      setValueHad400Error(false)
    }
  }, [error])

  return (
    <Box sx={{ width: { xs: '100%', sm: '384px' } }}>
      <Typography variant="h6" component={'h2'} sx={{ mb: 3 }}>
        {serviceContent.verifyHeading}
      </Typography>
      <TextField
        sx={{
          display: { xs: 'inline-flex', sm: 'flex' },
          mb: 2,
          width: { xs: '100%', sm: '240px' },
        }}
        inputProps={{ 'data-testid': 'inputVerificationCode' }}
        aria-invalid={(code && !code.success) || valueHad400Error}
        error={(code && !code.success) || valueHad400Error}
        placeholder={serviceContent.verifyPlaceHolder}
        helperText={
          (!code?.success && code?.error.issues[0].message) ||
          (valueHad400Error && serviceContent.errorVerifyCodeNotCorrect)
        }
        onBlur={handleBlurInput}
        type={'number'}
        label={serviceContent.verifyLabel}
        onChange={handleChangeInput}
      />
      {!tokenComparison && (
        <Button
          onClick={handleClickButton}
          data-testid="buttonVerifyCode"
          sx={{ width: { xs: '100%', sm: '130px' }, mb: 5 }}
        >
          {serviceContent.verifyButtonText}
        </Button>
      )}
      {tokenComparison && (
        <Box mb={5}>
          <CircularProgress />
        </Box>
      )}
      {(showDialogNoTokenRefresh || showRefreshedTokenDialog) && (
        <OutcomeDialog open={successVerify} type="verifySuccess" />
      )}
      <OutcomeDialog
        open={!!error && 'status' in error && error.status === 500}
        type="verifyError"
      />
    </Box>
  )
}

export default VerificationBox
