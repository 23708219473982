import { useUser } from '@trr/app-shell-data'
import { useLazyPutContactInfoQuery } from 'api'
import { SyntheticEvent, useState } from 'react'
import { ServiceProps, InferZodSafeReturnType } from 'types'
import { isSamePhoneNumber } from 'utils'

import {
  ContactDetailBox,
  OutcomeDialog,
  Header,
  MissingCodeBox,
  VerificationBox,
} from './components'

const ChangeInfo = ({ endpoints, schema, inputType }: ServiceProps) => {
  const [trigger, result] = useLazyPutContactInfoQuery()
  const [info, setInfo] = useState<InferZodSafeReturnType<typeof schema>>()
  const [shouldNotVerify, setShouldNotVerify] = useState(false)
  const { isSuccess, originalArgs, data, error } = result
  const user = useUser()
  const typeOverlapUserObject = inputType === 'tel' ? 'mobilePhone' : 'email'
  const infoByType = user?.[typeOverlapUserObject]
  const dataToVerify = {
    [typeOverlapUserObject]: info?.success ? info.data : null,
  }
  const successNetworkShouldVerify =
    isSuccess &&
    !originalArgs?.endpoint.includes('verification') &&
    data.statusCode !== 201

  const handleBlurInput = (event: SyntheticEvent) => {
    const eventTarget = event.target as HTMLInputElement
    setInfo(schema.safeParse(eventTarget.value))
  }

  const handleClickButton = () => {
    if (typeof info === 'undefined') {
      setInfo(schema.safeParse(''))
      return
    }
    if (info.success) {
      const detailIsSame =
        info.data === infoByType || isSamePhoneNumber(info.data, infoByType)
      if (detailIsSame) {
        setShouldNotVerify(true)
        return
      }
      void trigger({
        endpoint: endpoints.set,
        body: info.data,
      })
    }
  }

  return (
    <>
      <Header />
      <ContactDetailBox
        showSuccessText={successNetworkShouldVerify}
        buttonHandler={handleClickButton}
        blurHandler={handleBlurInput}
        inputType={inputType}
        contactInfo={info}
        error={error}
      />
      <VerificationBox
        endpoint={endpoints.verify}
        dataToVerify={dataToVerify}
      />
      <MissingCodeBox buttonHandler={handleClickButton} />
      <OutcomeDialog open={shouldNotVerify} type="setSuccess" />
      <OutcomeDialog
        open={!!error && 'status' in error && error.status === 500}
        type="setError"
      />
    </>
  )
}

export default ChangeInfo
