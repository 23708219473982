const isSamePhoneNumber = (
  number: string,
  compareNumber: string | undefined
) => {
  if (!number || !compareNumber) {
    return false
  }
  if (isNaN(parseInt(number)) || isNaN(parseInt(number))) {
    return false
  }

  return number === compareNumber
}

export default isSamePhoneNumber
