import { Box, Button, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'
import { SyntheticEvent } from 'react'

const MissingCodeBox = ({
  buttonHandler,
}: {
  buttonHandler: (event: SyntheticEvent) => void
}) => {
  const { serviceContent } = useEpiContent<Content>()

  return (
    <Box sx={{ width: { xs: '100%', sm: '384px' } }}>
      <Typography variant="body2" fontWeight={600} mb={1}>
        {serviceContent.verifyNoCodeLabel}
      </Typography>
      <Button onClick={buttonHandler} variant="text">
        {serviceContent.verifyNoCodeButtonText}
      </Button>
    </Box>
  )
}

export default MissingCodeBox
