import { Box, Button, TextField, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content, InferZodSafeReturnType, ServiceTypes } from 'types'
import { SyntheticEvent, useEffect, useState } from 'react'
import { z } from 'zod'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { type SerializedError } from '@reduxjs/toolkit'

const ContactDetailBox = ({
  showSuccessText,
  buttonHandler,
  blurHandler,
  contactInfo,
  inputType,
  error,
}: {
  showSuccessText: boolean
  buttonHandler: (event: SyntheticEvent) => void
  blurHandler: (event: SyntheticEvent) => void
  contactInfo:
    | InferZodSafeReturnType<z.ZodIntersection<z.ZodString, z.ZodString>>
    | undefined
  inputType: ServiceTypes
  error: FetchBaseQueryError | SerializedError | undefined
}) => {
  const { serviceContent } = useEpiContent<Content>()
  const [valueHad400Error, setValueHad400Error] = useState(false)

  const handleChangeInput = (event: SyntheticEvent) => {
    if (error && 'status' in error && 'originalPayload' in error) {
      const eventTarget = event.target as HTMLInputElement
      if (error.status === 400 && eventTarget.value === error.originalPayload) {
        setValueHad400Error(true)
        return
      }
      setValueHad400Error(false)
    }
  }
  useEffect(() => {
    if (error && 'status' in error && error.status === 400) {
      setValueHad400Error(true)
    } else {
      setValueHad400Error(false)
    }
  }, [error])

  return (
    <Box sx={{ width: { xs: '100%', sm: '384px' } }} mb={5}>
      <Typography variant="h6" component={'h2'} sx={{ mb: 3 }}>
        {serviceContent.setHeading}
      </Typography>
      <TextField
        inputProps={{ 'data-testid': 'inputContactInfo' }}
        sx={{ mb: 1 }}
        aria-invalid={(contactInfo && !contactInfo.success) || valueHad400Error}
        error={(contactInfo && !contactInfo.success) || valueHad400Error}
        autoComplete={inputType}
        helperText={
          (!contactInfo?.success && contactInfo?.error.issues[0].message) ||
          (valueHad400Error && serviceContent.errorSetInfoNotCorrect)
        }
        name={inputType}
        onBlur={blurHandler}
        type={inputType}
        label={serviceContent.setLabel}
        fullWidth
        onChange={handleChangeInput}
      />
      <Typography variant="body2" mb={2}>
        {serviceContent.setCallbackInfo}
      </Typography>
      <Button
        data-testid="buttonRequestCode"
        onClick={buttonHandler}
        variant="outlined"
        sx={{ width: { xs: '100%', sm: '230px' }, mb: 1 }}
      >
        {serviceContent.setButtonText}
      </Button>
      {showSuccessText && (
        <Typography variant="body2">{serviceContent.setSuccessText}</Typography>
      )}
    </Box>
  )
}

export default ContactDetailBox
