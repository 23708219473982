import { PropsByService } from 'types'
import { z } from 'zod'

export const getServicesConfig = ({
  setErrorNonEmpty,
  setErrorFormat,
}: {
  setErrorNonEmpty: string
  setErrorFormat: string
}): PropsByService => ({
  changeEmail: {
    endpoints: {
      set: '/user/contact/email',
      verify: '/user/contact/email/verification',
    },
    schema: z
      .string()
      .min(1, setErrorNonEmpty)
      .and(z.string().email(setErrorFormat)),
    inputType: 'email',
  },
  changeCell: {
    endpoints: {
      set: '/user/contact/mobile-phone',
      verify: '/user/contact/mobile-phone/verification',
    },
    schema: z.string().min(1, setErrorNonEmpty),
    inputType: 'tel',
  },
})
